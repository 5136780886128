<template>
  <Details
    ref="details"
    title="Customer"
    resource="customers"
    :base-path="basePath"
    routeIdParam="customerId"
    :redirect-saved="false"
    title-template="{name} company details"
    :breadcrumb-template="isQuext ? '{name}' : '{name} company details'"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel>
        <template v-slot:heading>customer details</template>
        <template v-slot:text>Edit the basic information about customer, including required fields.</template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <CustomerForm
        :initialValues="getInitialValues(detailsProps.record)"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import Details from '@/components/auth/details/Details';
  import CustomerForm from '@/views/auth/customers/CustomerForm';
  import AuthMixin from "@/components/auth/AuthMixin";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";

  export default {
    name: 'CustomersEdit',
    components: {
      CustomerForm,
      Details,
      DetailsSidePanel
    },
    mixins: [NotifyMixin, AuthMixin, ActionsMixin],
    computed: {
      basePath() {
        return this.isQuext ? this.$route.name.replace('edit', 'index') : 'bookmarks';
      }
    },
    methods: {
      getInitialValues({ accesses = [], ...record }) {
        return {
          ...record,
          apps: accesses.map(app => ({
            key: app.appId,
            value: app.appName,
            revoked: app.revoked,
          }))
        };
      },
      deactivateCustomer() {
        this.$authDataProvider.delete('customers', {id: this.$route.params.customerId})
          .then(() => {
            this.notifySuccess('Customer deactivated');
          })
          .catch(error => this.notifyError(error.message))
      }
    },
    mounted() {
      this.$watch('$refs.details.record', function (record) {
        const {deleted} = record;

        if (!deleted && this.isQuextAdmin) {
          this.addConfirmAction(
            {
              id: 'auth-deactivate-customer',
              title: "deactivate customer",
              showConfirmation: true,
              confirmationMessage: `Are you sure you want to deactivate this customer?`,
              confirmationType: 'warning',
              confirmBtnText: 'yes',
              cancelBtnText: 'no'
            },
            this.deactivateCustomer
          )
        }
      });
    }
  };
</script>
